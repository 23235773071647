import type { Drop } from 'models/Drop'
import { HTTPMethod } from 'utils/HTTPUtils';
import { BASE_URI, authenticatedHeaders } from './api';

export const DROPS_URI = `${BASE_URI}/api/drops`

export type GetDropsResponse = Drop[]

export const getDrops = async (): Promise<GetDropsResponse> => fetch(
  DROPS_URI, {
    method: HTTPMethod.GET,
    headers: await authenticatedHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Unable to fetch drops: error ${response.status}`)
    }
    return response.json() as Promise<GetDropsResponse>
  }
);

export const saveDrop = async ({ id, ...restDropProperties }: Drop): Promise<Drop> => fetch(
  DROPS_URI, {
    method: id ? HTTPMethod.PUT : HTTPMethod.POST,
    headers: await authenticatedHeaders(),
    body: JSON.stringify({ id, ...restDropProperties})
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Unable to save drop: error ${response.status}`)
    }
    return response.json() as Promise<Drop>;
  }
);

export const deleteDrop  = async (id: string): Promise<Drop> => fetch(
  `${DROPS_URI}/${id}`, {
    method: HTTPMethod.DELETE,
    headers: await authenticatedHeaders()
  }).then((response) => {
    if (!response.ok) {
      throw new Error(`Unable to delete drop: error ${response.status}`)
    }
    return response.json() as Promise<Drop>;
  }
);