import React, { ReactElement } from 'react'
import GoogleSignInBtn from 'elements/GoogleSignInBtn'
import FacebookSignInBtn from 'elements/FacebookSignInBtn'
import { reportUserClickedOnEvent } from 'utils/analytics/segment'
import { requestAuthenticateWithFacebook, requestAuthenticateWithGoogle } from 'services/AuthService'
import { useRouter } from 'next/router';
import { appRoutes } from 'config' 

const SocialSignIn = (): ReactElement => {
  const router = useRouter();

  // The user should be redirected if currently on the Login page
  const redirectUrl = (router.pathname === appRoutes.LOGIN) ? appRoutes.MEMORY_BOX : null;

  const handleGoogleBtnClick = () => {
    reportUserClickedOnEvent('Sign in with Google', 'Login Page');
    // TODO: RedirectUrl should probably not belong to the View, that's business logic that should
    // be handled seperately, maybe a seperate state machine logic for that
    // We should maybe use the useAuthenticationObserver and when login has happened then redirect the
    // user appropriately.
    requestAuthenticateWithGoogle(redirectUrl)
  }

  const handleFacebookBtnClick = () => {
    reportUserClickedOnEvent('Sign in with Facebook', 'Login Page');
    // TODO: RedirectUrl should probably not belong to the View, that's business logic that should
    // be handled seperately, maybe a seperate state machine logic for that
    // We should maybe use the useAuthenticationObserver and when login has happened then redirect the
    // user appropriately.
    requestAuthenticateWithFacebook(redirectUrl);
  }

  return (
    <div className="sm:flex items-center mx-auto">
      <div className="mr-2">
        <GoogleSignInBtn onClick={handleGoogleBtnClick} />
      </div>

      <div className="hidden sm:block text-gray-400 font-bold text-xs ml-2 mr-2">
        OR
      </div>

      <div className="flex sm:hidden items-center mt-10 mb-4">
        <hr className="flex-auto" />
        <span className="px-3 font-bold text-sm text-gray-400">
          OR
        </span>
        <hr className="flex-auto" />
      </div>

      <div className="ml-2">
        <FacebookSignInBtn onClick={handleFacebookBtnClick} />
      </div>
    </div>
  )
};

export default SocialSignIn;