import React from 'react';
import SocialSignIn from 'elements/SocialSignIn';
import { useAuthentication } from 'hooks/useAuthentication';
import { requestToCancelAuthentication, requestToLogout } from 'services/AuthService'

const Login = (): JSX.Element => {
  const {
    isAuthenticating,
    isAuthenticated,
    isUnauthenticated
  } = useAuthentication();

  const handleLogoutBtnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    requestToLogout()
  }

  const handleCancelBtnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    requestToCancelAuthentication()
  }

  return (
    <div className="max-w-xl mx-auto px-4 sm:px-6 lg:px-8 text-center my-5">

      <h2 className="mb-4">
        <span className="text-xl sm:text-2xl block mb-2 font-extrabold tracking-tight text-gray-900">Ready to dive in?</span>
        <span className="text-3xl sm:text-4xl block text-mf-blue mb-4 font-bold tracking-tight">
          Use MindFeed today.
        </span>
      </h2>

      <div>
        {(() => {
          if (isAuthenticating) {
            return <p>Authentication...</p>
          }

          if (isAuthenticated)
            return <p>Authenticated</p>
          }
        )()}
      </div>

      <div className="flex items-center mt-10 mb-4">
        <hr className="flex-auto" />
        <span className="px-3 font-bold text-sm text-gray-400">
          GET STARTED
        </span>
        <hr className="flex-auto" />
      </div>

      <div className="mt-8 flex">
        { isUnauthenticated && (
          <SocialSignIn />
        )}

        {/* Logout */}
        { isAuthenticating && (
          <div className="ml-3 inline-flex">
            <a
              onClick={(e) => handleCancelBtnClick(e)}
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
            >
              Cancel
            </a>
          </div>
        )}

        {/* Logout */}
        { isAuthenticated && (
          <div className="ml-3 inline-flex">
            <a
              onClick={(e) => handleLogoutBtnClick(e)}
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200"
            >
              Logout
            </a>
          </div>
        )}
      </div>
    </div>
  )
}

export default Login;