import { AuthMethodType, AuthRegistrationType, providerIdToAuthMethodType } from "models/Auth";
import { SubscriptionType } from "models/Subscription";
import { AnalyticsEventName, IAnalyticsEvent } from "./events";
import { User as FirebaseUser } from '@firebase/auth'

export const SEGMENT_WRITE_KEY = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

declare global {
  interface Window {
    analytics: any;
  }
}

export interface SegmentIdentity {
  cloudIdentityId: string,
  registeredAt: string,
  name: string,
  email: string,
  emailVerified: boolean,
  avatarURL: string,
  authMethod: string,
  subscription: string 
}

export interface SegmentIdentifiable {
  toSegmentIdentity(): Partial<SegmentIdentity>;
}

export const toSegmentIdentity = (user: FirebaseUser): Partial<SegmentIdentity> => ({
  cloudIdentityId: user.uid,
  name: user.displayName ?? undefined,
  email: user.email ?? undefined,
  emailVerified: user.emailVerified ?? undefined,
  avatarURL: user.photoURL ?? undefined,
  authMethod: providerIdToAuthMethodType(user.providerData[0].providerId),
  subscription: SubscriptionType.Free
})

export const isSegmentImplemented = (): boolean => {
  return (SEGMENT_WRITE_KEY !== '');
}

export const reportPageView = (url: string = '') => {
  if (!isSegmentImplemented()) return;

  window.analytics.page(url);
}

export const reportEvent = (event: IAnalyticsEvent) => {
  if (!isSegmentImplemented()) return;

  window.analytics.track(event.name, event.properties);
}

// Events

export const reportUserRegisteredEvent = (subscription: SubscriptionType, typeOfRegistration: AuthRegistrationType) => reportEvent({
  name: AnalyticsEventName.USER_REGISTERED,
  properties: {
    subscription,
    typeOfRegistration
  }
});

export const reportUserLoggedInEvent = (typeOfLogin: AuthMethodType) => reportEvent({
  name: AnalyticsEventName.USER_LOGGED_IN,
  properties: {
    typeOfLogin
  }
});

export const reportUserLoggedOutEvent = () => reportEvent({
  name: AnalyticsEventName.USER_LOGGED_OUT,
  properties: {}
});

export const reportUserClickedOnEvent = (title: string, location?: string) => reportEvent({
  name: AnalyticsEventName.USER_CLICKED,
  properties: {
    title,
    location
  }
});

/**
 * The Segment Identify call lets us tie a user to their actions and record traits about them. 
 * It includes a unique User ID and any optional traits we know about the user, like their email, name, etc.
 * 
 * Segment recommends that you make an Identify call: 
 * 
 * - After a user first registers 
 * - After a user logs in 
 * - When a user updates their info (for example, they change or add a new address)
 * - Upon loading any pages that are accessible by a logged in user (optional)
 * 
 * Ref. https://segment.com/docs/connections/spec/identify/
 */
export const reportIdentity = (identity: Partial<SegmentIdentity>) => {
  if (!isSegmentImplemented()) return;

  window.analytics.identify(identity.cloudIdentityId, { 
    ...(delete identity.cloudIdentityId && identity)
  });
}