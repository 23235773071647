
import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, ReactElement, useRef } from 'react'
import { closeModal } from 'services/ModalService';
import LoginModal from './LoginModal';
import { useModal } from 'hooks/useModal';
import { ModalView } from 'machines/ModalMachine';
import ActionableModal from './ActionableModal';
import SaveQuoteModal from './SaveQuoteModal';

const Modal = (): ReactElement => {
  const cancelButtonRef = useRef(null);
  const { isOpen, view } = useModal();

  const renderModal = () =>  {
    switch(view) {
      case ModalView.Login:
        return <LoginModal />;
      case ModalView.SaveQuote:
        return <SaveQuoteModal />;
      case ModalView.Actionable:
        return <ActionableModal />;
    }
  }

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={closeModal}>
        { renderModal()}
      </Dialog>
    </Transition.Root>
  );
}

export default Modal;