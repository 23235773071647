import { Fragment, useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { TagIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames';
import { Collection } from 'models/Collection'

export enum ButtonPosition {
  Left,
  Center,
  Right
}

export enum ListBoxPosition {
  AboveButton,
  BelowButton
}

interface Props {
  collections: Collection[];
  isLoading: boolean;
  buttonPosition?: ButtonPosition,
  listBoxPosition?: ListBoxPosition;
  defaultCollection?: Collection;
  didSelectCollection?: ((collection: Collection|null) => void);
}

export const CollectionSelector: React.FC<Props> = ({
  collections,
  isLoading,
  buttonPosition = ButtonPosition.Right,
  listBoxPosition = ListBoxPosition.BelowButton,
  defaultCollection = undefined,
  didSelectCollection
}) => {
  const [selectedCollection, setSelectedCollection] = useState(defaultCollection)

  const onChange = (value) => {
    if (value) {
      const selectedCollection = collections.find(col => col.id === value);
      setSelectedCollection(selectedCollection);
      didSelectCollection(selectedCollection);
    } else {
      setSelectedCollection(null);
      didSelectCollection(null);
    }
  }

  // set the initial form values
  useEffect(() => {
      setSelectedCollection(defaultCollection);
  }, [defaultCollection]);


  // set the initial form values
  useEffect(() => {
    if (!selectedCollection) {
      return;
    }
    // If there is a change in the `collections` and the `selectedCollection` has been removed from it,
    // automatically assign the `selectedCollection` to `null`
    const selectedValueStillExists = collections.find(col => col.id === selectedCollection.id)
    if (!selectedValueStillExists) {
      setSelectedCollection(null);
    }
  }, [collections, defaultCollection]);

  const collectionName = (() => {
    if (selectedCollection === undefined) {
      return 'Choose Collection'
    }

    if (selectedCollection === null) {
      return 'All'
    }

    return selectedCollection.name
  })();

  return (isLoading) ? (
      <Fragment />
    ) : (
    <Listbox as="div" value={selectedCollection} onChange={onChange} className="shrink-0">
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Add a collection</Listbox.Label>
          <div className={classNames("relative",
            open && "w-full min-w-[200px]",
            buttonPosition === ButtonPosition.Right && "text-right",
            buttonPosition === ButtonPosition.Left && "text-left",
            buttonPosition === ButtonPosition.Center && "text-center",
          )}>
            <Listbox.Button className="relative inline-flex items-center rounded-full py-2 px-2 bg-gray-50 text-sm font-medium text-gray-500 whitespace-nowrap hover:bg-gray-100 sm:px-3">
              <TagIcon
                className={classNames(
                  selectedCollection === null ? 'text-gray-300' : 'text-gray-500',
                  'shrink-0 h-5 w-5 sm:-ml-1'
                )}
                aria-hidden="true"
              />
              <span
                className={classNames(
                  selectedCollection === null ? '' : 'text-gray-900',
                  'truncate ml-2'
                )}
              >
                { collectionName }
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className={ classNames(
                "absolute z-10 w-full rounded-md bg-white shadow-lg min-w-60",
                listBoxPosition === ListBoxPosition.AboveButton ? "mb-1 bottom-full" : "mt-1 top-full",
              )}
            >
              <Listbox.Options
                className="max-h-60 overflow-y-auto rounded-lg py-3 text-base leading-6 shadow bg-white focus:outline-none sm:text-sm sm:leading-5 ring-1 ring-black/5"
              >
                <Listbox.Option
                  key='all'
                  className={({ active }) =>
                    classNames(
                      active ? 'bg-gray-100' : 'bg-white',
                      'cursor-default select-none relative py-2 px-3'
                    )
                  }
                  value={null}
                >
                  <div className="flex items-center">
                    <span className="block font-medium truncate">All</span>
                  </div>
                </Listbox.Option>
                {collections && collections.map((collection) => (
                  <Listbox.Option
                    key={collection.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-100' : 'bg-white',
                        'cursor-default select-none relative py-2 px-3'
                      )
                    }
                    value={collection.id}
                  >
                    <div className="flex items-center">
                      <span className="block font-medium truncate">{collection.name}</span>
                    </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
};