import React, { FC } from 'react'
import styles from './GoogleSignInBtn.module.css'
import classNames from 'classnames'
import Head from 'next/head'

interface GoogleSignInBtnProps {
  size?: string
  onClick?: () => void
}

const GoogleSignInBtn: FC<GoogleSignInBtnProps> = ({
  size = 'normal',
  onClick = () => {
    // Do nothing
  },
}): JSX.Element => {
  const handleBtnClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    onClick()
  }

  const btnStyle = (() => {
    switch (size) {
      case 'small':
        return classNames(styles.container, styles.small)
      case 'medium':
        return classNames(styles.container, styles.medium)
      default:
        return classNames(styles.container, styles.normal)
    }
  })()

  return (
    <>
      <Head>
        <link
          rel="prefetch"
          type="image"
          href="/btn-google-signin-light-normal.png"
        />
        <link
          rel="prefetch"
          type="image"
          href="/btn-google-signin-light-hover.png"
        />
        <link
          rel="prefetch"
          type="image"
          href="/btn-google-signin-light-pressed.png"
        />
      </Head>
      <button className={btnStyle} onClick={(event) => handleBtnClick(event)} tabIndex={-1}>
        <span className="sr-only">Signin with Google</span>
      </button>
    </>
  )
}

export default GoogleSignInBtn;