import { User as FirebaseUser } from '@firebase/auth'

export type AuthRegistrationType = AuthMethodType;

export enum AuthMethodType {
  Apple = 'apple',
  Facebook = 'facebook',
  Google = 'google',
  Email = 'email',
  Unknown = 'unknown'
}

export class Auth {
  authMethods: AuthMethodType[] = [];

  public constructor(init?: Partial<Auth>) {
    Object.assign(this, init);
  }
}

export const providerIdToAuthMethodType = (providerId: string|undefined): AuthMethodType => {
  if (!providerId) {
    return AuthMethodType.Unknown
  }

  if (providerId.includes('google')) {
    return AuthMethodType.Google;
  }

  if (providerId.includes('facebook')) {
    return AuthMethodType.Facebook;
  }

  if (providerId.includes('apple')) {
    return AuthMethodType.Apple;
  }

  return AuthMethodType.Unknown;
} 

export const fromFirebaseUser = (user: FirebaseUser): Auth => new Auth({
  authMethods: user.providerData.map((userInfo) => providerIdToAuthMethodType(userInfo.providerId))
});