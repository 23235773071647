import { appRoutes } from "config";
import { isBrowser} from 'utils/BrowserUtils'
import type { Router } from 'next/router';
import type { ReactElement } from 'react'
import { useAuthentication } from "hooks/useAuthentication";

interface Props {
  router: Router
  children: ReactElement
}

const RouteProtector = ({ router, children }: Props): ReactElement => {
  const { isAuthenticated } = useAuthentication();

  // We authorise these exact paths to be NOT AUTHENTICATED
  const unprotectedExactPaths = [
    appRoutes.HOME,
    appRoutes.LOGIN,
    appRoutes.PRIVACY_POLICY,
    appRoutes.TERMS_OF_SERVICE,
    appRoutes.HELP_CENTRE,
    appRoutes.FACEBOOK_DATA_DELETION
  ];
  /**
   * @var exactPathMatchesUnprotectedRoutes Checks if current path matches exactly one of the path in the
   * `unprotectedExactPaths` array
   */
  const exactPathMatchesUnprotectedRoutes = unprotectedExactPaths.indexOf(router.pathname) != -1

  if (exactPathMatchesUnprotectedRoutes) {
    return children;
  }

  // We authorise paths starting by these to be NOT AUTHENTICATED
  const unprotectedStartingWithPaths = [
    appRoutes.QUOTES
  ]

  /**
   * @var unprotectedStartingWithPaths Checks if current path starts exactly with the one of the path string in the
   * `unprotectedStartingWithPaths` array
   */
  const startingPathMatchesUnprotectedRoutes = (unprotectedStartingWithPaths.filter(path => router.pathname.startsWith(path))).length !== 0;

  if (startingPathMatchesUnprotectedRoutes) {
    return children;
  }

  // Everything else is sent to the Login page
  if (isBrowser() && !isAuthenticated) {
    router.push(appRoutes.LOGIN);
  }

  return children;
};

export default RouteProtector;