import { User as FirebaseUser, UserCredential } from '@firebase/auth'
import { AuthMethodType } from './Auth';

export class User {
  cloudIdentityId: string
  emailVerified: boolean
  email?: string
  name?: string
  avatarURL?: string

  public constructor(init?: Partial<User>) {
    Object.assign(this, init);
  }

  hasAvatar(): boolean {
    return this.avatarURL !== undefined;
  }

  // e.g. getFirstName for "Paul Steve Panakkal" returns "Paul Steve"
  getFirstName(): string {
    return this.name.split(' ').slice(0, -1).join(' ');
  }

  // e.g. getLastName for "Paul Steve Panakkal" returns "Paul Steve"
  getLastName(): string {
    return this.name.split(' ').slice(-1).join(' ');
  }

  // e.g. getDisplayName for "Paul Steve Panakkal" returns "Paul Steve P."
  getDisplayName(): string {
    return (
      this.getFirstName() + ' ' + this.getLastName().charAt(0).toUpperCase() + '.'
    );
  }

  // e.g. getUsername for "whatever@gmail.com" returns @whatever
  getUsername(): string {
    return '@' + this.email.substr(0, this.email.indexOf('@'));
  }

  getHigResolutionAvatarURL(method?: AuthMethodType) {
    switch (method) {
      case AuthMethodType.Facebook:
        return `${this.avatarURL}?type=large`;
      case AuthMethodType.Google: 
        return this.avatarURL?.replace('s96-c', 's400-c');
      default:
        return this.avatarURL;
    }
  }

}

export const fromFirebaseCredential = (userCredential: UserCredential): User => fromFirebaseUser(userCredential.user)

export const fromFirebaseUser = (user: FirebaseUser): User => {
  return new User({
    cloudIdentityId: user.uid,
    emailVerified: user.emailVerified,

    name: user.displayName ?? undefined,
    email: user.email ?? undefined,
    avatarURL: user.photoURL ?? undefined,
  });
}