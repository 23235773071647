import { Dialog, Transition } from '@headlessui/react';
import { useModal } from 'hooks/useModal';
import React, { Fragment, ReactElement } from 'react'
import { SquaresPlusIcon, ArrowDownCircleIcon } from '@heroicons/react/24/outline'
import { SaveQuoteModalPayload } from 'machines/ModalMachine';
import { closeModal } from 'services/ModalService';
import TextSeparator, { TextPosition } from 'elements/TextSeparator';
import { SaveQuoteForm } from 'elements/Drop/SaveQuoteForm';

const SaveQuoteModal = (): ReactElement => {

  // Downcast to payload to the appropriate type
  const { payload } = useModal() as { payload: SaveQuoteModalPayload };
  const showTitle = false;

  return (
    <>
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </Transition.Child>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">

              <div>
                { showTitle &&
                  <div className="flex row items-center justify-center mt-5 mb-3">
                    <div className="mr-2 flex h-12 w-12 items-center justify-center rounded-full bg-blue-100">
                      <SquaresPlusIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>

                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      Add to my Memory Box
                    </Dialog.Title>
                  </div>
                }

                  <div className="text-center">
                    {/* Quote */}
                    <div className="bg-gradient-to-r from-mf-dark-blue to-blue-600 pb-4 lg:relative lg:z-10">
                      <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                        <blockquote>
                          <div className="px-6 pt-2">
                            <svg
                              className="h-12 w-12 text-white opacity-25"
                              fill="currentColor"
                              viewBox="0 0 32 32"
                              aria-hidden="true">
                              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                            </svg>
                            <p className="mt-2 text-2xl font-medium text-white">
                              { payload.quote.content }
                            </p>
                          </div>
                          <footer className="mt-6 px-6">
                            <TextSeparator
                              text={payload.quote.author.name}
                              textPosition={TextPosition.Center}
                              textStyle="text-base font-medium text-white"
                              />
                          </footer>
                        </blockquote>
                      </div>
                    </div>
                  </div>

                  <div className="text-center">
                    <ArrowDownCircleIcon className="h-8 w-8 text-blue-600 mx-auto my-2" aria-hidden="true" />
                  </div>

                  <SaveQuoteForm quote={payload.quote} onCancel={closeModal} onSuccess={closeModal} />

                </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </>
  );
}

export default SaveQuoteModal