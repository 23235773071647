import getFirebase from "services/firebase";
import { HTTPContentType } from 'utils/HTTPUtils';

export const BASE_URI = process.env.NEXT_PUBLIC_MINDFEED_BASE_URI;

export const authenticatedHeaders = async (): Promise<HeadersInit> => {
  const token = await getFirebase().auth().currentUser.getIdToken(false)
  const headers = new Headers({
    'Content-Type': HTTPContentType.JSON,
    'Authorization': `Bearer ${token}`
  });

  return headers;
}