import { GrowthBook } from "@growthbook/growthbook-react";
import { environment, growthbook as config } from 'config/global';

const growthbook = new GrowthBook({
  apiHost: config.apiHost,
  clientKey: config.clientKey,
  enableDevMode: environment === 'development'
});

/**
 * List of feature flags
 */
export enum FEATURE_FLAGS {
  KILL_SWITCH_DISABLE_OPEN_GRAPH_PREVIEW = 'kill-switch-disable-open-graph-preview',
}

export default growthbook;