import ContentLoader from 'react-content-loader'

export const DropFormSkeleton = (): JSX.Element => {
  return (
  <div className="bg-white border border-gray-200 rounded-lg shadow-sm overflow-hidden mb-5 mx-5 pt-5">
    <div className="px-3">
      <ContentLoader animate={true} foregroundColor={'#CED8F3'} width={'100%'} height={'100px'}>
        <rect x="0" y="0px" width="100%" height="20px" />
        <rect x="0" y="30px" width="40%" height="20px" />
        <rect x="86%" y="60px" width="14%" height="30px" rx="15" />
      </ContentLoader>
    </div>
    <div>
      <ContentLoader animate={true} foregroundColor={'#CED8F3'} width={'100%'} height={'1px'}>
        <rect x="0" y="0px" width="100%" height="1px" />
      </ContentLoader>
    </div>
    <div className="p-3">
      <ContentLoader animate={true} foregroundColor={'#CED8F3'} width={'100%'}  height={'30px'}>
        <rect x="89%" y="0px" width="11%" height="30px" rx="5" />
      </ContentLoader>
    </div>
  </div>
  )
};