
import { interpret, State } from 'xstate';
import { authMachine, EventType } from 'machines/AuthMachine';
import { AuthMethodType } from 'models/Auth';

// @ts-ignore
const stateDefinition =
  typeof window !== 'undefined'
    ? JSON.parse(
        localStorage.getItem(
          process.env.NEXT_PUBLIC_LOCAL_STORAGE_KEY_AUTH_SERVICE_STATE
        )
      )
    : undefined

let resolvedState
if (stateDefinition) {
  const previousState = State.create(stateDefinition)

  // @ts-ignore
  resolvedState = authMachine.resolveState(previousState)
}

// Singleton / Shared instance
export const AuthService = interpret(authMachine)
  .onTransition((state) => {
    if (typeof window !== 'undefined' && state.changed) {
      localStorage.setItem(
        process.env.NEXT_PUBLIC_LOCAL_STORAGE_KEY_AUTH_SERVICE_STATE,
        JSON.stringify(state)
      )
    }
  })
  .start(resolvedState);

  // Helpers

export const requestAuthenticateWithFacebook = (redirectUrl?: string): void => {
  AuthService.send(EventType.RequestAuthentication, {
    loginType: AuthMethodType.Facebook,
    redirectUrl
  });
}

export const requestAuthenticateWithGoogle = (redirectUrl?: string): void => {
  AuthService.send(EventType.RequestAuthentication, {
    loginType: AuthMethodType.Google,
    redirectUrl
  });
}

export const requestAuthenticateWithApple = (redirectUrl?: string): void => {
  AuthService.send(EventType.RequestAuthentication, {
    loginType: AuthMethodType.Apple,
    redirectUrl
  });
}

export const requestToCancelAuthentication = (): void => {
  AuthService.send(EventType.CancelAuthentication);
}

export const requestToLogout = (): void => {
  AuthService.send(EventType.RequestLogout);
}