import { createMachine, assign, spawn } from 'xstate'
import { Notification, NotificationKind } from 'models/Notification'

type NotificationTypedStates =
  | {
      value: 'ready'
      context: NotificationMachineContext
    }

export type NotificationMachineEvents =
  | { type: 'NOTIFICATION.SHOW'; notification: Notification; }
  | { type: 'NOTIFICATION.HIDE'; notification: Notification; }

export interface NotificationMachineContext {
  notifications: Notification[]
}

export const notificationMachine = createMachine<
  NotificationMachineContext,
  NotificationMachineEvents,
  NotificationTypedStates
>({
  id: 'notification',
  context: {
    notifications: []
  },
  initial: 'ready',
  states: {
    ready: {},
  },
  on: {
    'NOTIFICATION.SHOW': {
      actions: assign({
        notifications: (context, event: any) => {
          const notifs = context.notifications;
          notifs.push(event.notification);
          return notifs;
        }
      }),
    },
    'NOTIFICATION.HIDE': {
      actions: assign({
        notifications: (context, event: any) => {
          // We use Animations so we cannot remove the notification entirely,
          // we mark it as not visible instead.
          return context.notifications.map(notif => {
            if (notif.id === event.notification.id) {
              // Mark it as invisible
              return {...notif, visible: false};
            }
          
            return notif;
          });
          

          // If we wanted to remove the notification from the stack
          // return context.notifications
          //   .filter((notif) => notif.id !== event.notification.id);
        }
      }),
    },
  },
})