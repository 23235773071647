import { Quote } from 'models/openGraph/Quote';
import { createMachine, assign } from 'xstate'

export enum ModalView {
  Login,         // refers to component Modal/LoginModal
  Actionable,    // refers to component Modal/ActionableModal
  SaveQuote      // refers to component Modal/SaveQuoteModal
}

export type LoginModalPayload = {
  redirectTo: string;
};

export type ActionableModalPayload = {
  // ...
};

export type SaveQuoteModalPayload = {
  quote: Quote
};

export type ModalPayload = | LoginModalPayload
  | ActionableModalPayload
  | SaveQuoteModalPayload;

// State

export enum StateValue {
  Closed = 'CLOSED',
  Open = 'OPEN',
};

const CLOSED = StateValue.Closed;
const OPEN = StateValue.Open;

type ModalMachineState = | {
  value: StateValue.Open
  context: ModalMachineContext
} | {
  value: StateValue.Closed
  context: ModalMachineContext
};

// Events

export enum EventType {
  RequestOpen = 'REQUEST_OPEN',
  RequestClose = 'REQUEST_CLOSE'
};

type RequestOpenModalEvent = {
  type: EventType.RequestOpen;
  view?: ModalView;
  payload?: ModalPayload;
}

type RequestCloseModalEvent = {
  type: EventType.RequestClose;
  view?: ModalView;
}

export type ModalMachineEvent = | 
  RequestOpenModalEvent |
  RequestCloseModalEvent;

export interface ModalMachineContext {
  view?: ModalView;
  payload?: ModalPayload;
}

/**
 * Check if an event is of type T - this is basically saying:
 * 
 * ```
 * const isRequestCloseMachineEvent = (event : ModalMachineEvent): event is RequestCloseMachineEvent => {
 *   return (event as RequestCloseMachineEvent) !== undefined;
 * }
 * ```
 */
const isEvent = <T extends ModalMachineEvent>(event: ModalMachineEvent): event is T => {
  return (event as T) !== undefined;
}

const shouldCloseModal = (context: ModalMachineContext, event: ModalMachineEvent) => {
  // Do not close the modal if the wrong event has been fired
  if (!isEvent<RequestCloseModalEvent>(event)) {
    return false;
  }

  // Close the modal if no views are provided 
  if ((context.view == null) || (event.view == null )) {
    return true;
  }

  // Close the modal if the views do match 
  if (context.view === event.view) {
    return true
  }

  // Do not close the modal otherwise
  return false
};

export const modalMachine = createMachine<
  ModalMachineContext,
  ModalMachineEvent,
  ModalMachineState
>(
  {
    id: 'modal',
    context: {
      view: null,
      payload: null,
    },
    initial: CLOSED,
    states: {
      [CLOSED]: {
        exit: 'reset',
        on: {
          [EventType.RequestOpen]: {
            target: OPEN,
            actions: [
              'setView',
              'setPayload'
            ],
          }
        },
      },
      [OPEN]: {
        exit: 'reset',
        on: {
          [EventType.RequestClose]: {
            target: CLOSED,
            cond: shouldCloseModal
          },
          [EventType.RequestOpen]: {
            target: OPEN,
            actions: [
              'setView',
              'setPayload'
            ],
          }
        }
      },
    },
  },
  {
    services:{
      // Services
    },
    actions: {
      reset: assign((_ctx: any, _event: any) => ({
        payload: undefined,
        view: undefined
      })),
      setPayload: assign((_ctx: any, event: any) => {
        return ({
          payload: event.payload,
      })}),
      setView: assign((_ctx: any, event: any) => {
        return ({
          view: event.view,
      })}),
    }
  }
)